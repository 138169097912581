import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import pathObj from "../../services/Config";
import { CommonService } from "../../services/common";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { checkImage, decryptData, errorHandling, UpdatedDecimalDigits } from "../../helpers/common";
import AlternativeRelativeProducts from "./AlternativeRelativeProducts";
import { CartContext } from "../../context/cartContext";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import getSymbolFromCurrency from "currency-symbol-map";
const ProductDetails = () => {
  const { itemCode } = useParams();
  const ItemId = itemCode
  const [productDetails, setProductDetails] = useState([]);
  const [attributesListDetails, setAttributesListDetails] = useState([]);
  const [quantities, setQuantities] = useState({});
  const hasFetched = useRef(false);
  const userInfo = window.localStorage.getItem('userData')
  const userD = decryptData(userInfo)
  const compData = JSON.parse(userD)
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const customerId = compData?.customer_id;
  const [priceBreakDown, setPriceBreakDown] = useState('');
  const { addToCartCount } = useContext(CartContext);
  const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
  const category = localStorage.getItem('categoriesName')
  const productType = localStorage.getItem('productType')
  const token = localStorage.getItem("Token");
  const [wishlistStatus, setWishlistStatus] = useState({});
  const [imageStatus, setImageStatus] = useState({});
  const [selectedPriceBreakdown, setSelectedPriceBreakdown] = useState({});
  const [zoom, setZoom] = useState({ display: 'none', backgroundPosition: 'center', backgroundImage: '' });
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [activeImage, setActiveImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [breadcrumbSlugs, setBreadcrumbSlugs] = useState([]);
  const productFilter = localStorage.getItem("productFilterString");

  // Decode the URL-encoded string
  const decodedFilter = decodeURIComponent(productFilter);
  // Split the string by '|'
  const categories = decodedFilter.split('|');
  // Assign each category to a variable
  const formattedCategory = categories[0]; // MPL/UPVC
  const formattedSubCategory = categories[1]; // Door Hardware
  const formattedChild = categories[2]; // Door Keeps


  const payTermsGrp = compData?.PayTermsGrpCode;

  const [categoryName = '', subCategoryName = '', childName = ''] = (productFilter || '').split('|');

  // const formattedCategory = categoryName.replace(/\s+/g, '-').replace(/[()]/g, '').toLowerCase();
  // const formattedSubCategory = subCategoryName.replace(/\s+/g, '-').replace(/[()]/g, '').toLowerCase();
  // const formattedChild = childName.replace(/\s+/g, '-');

  const imageContainerRef = useRef(null);
  // Mouse move handler for the zoom effect
  const handleMouseMove = (e) => {
    if (!activeImage) return; // Ensure an image is selected
    const { left, top, width, height } = imageContainerRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    setZoom({
      display: 'block',
      backgroundImage: `url(${activeImage})`,
      backgroundPosition: `${x}% ${y}%`,
    });
    setImageDimensions({ width, height });
  };

  // Initial active image load when product details are fetched
  useEffect(() => {
    if (imageStatus['Picture']) {
      setActiveImage(imageStatus['Picture'][0] || imageStatus['vectorPicture']); // Set the first image as the default active image
    }else if(imageStatus['vectorPicture']){
      setActiveImage(imageStatus['vectorPicture']);
    }
  }, [imageStatus]);

  // Mouse leave handler to remove zoom effect
  const handleMouseLeave = () => {
    setZoom({ display: 'none', backgroundPosition: 'center', backgroundImage: '' });
  };

  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const Currency = compData?.Currency;
      const PriceListNum = compData?.PriceListNum;
      const customer_id = customerId;
      const url = `${pathObj.baseURL}${pathObj.getProductDetails}?ItemCode=${ItemId}`;
      const payload = {
        Currency,
        PriceListNum,
        customer_id,
        category,
        productType,
      };
      const data = await CommonService.getAllWithBodyWithToken(url, payload);
      localStorage.setItem('productFilterString', data?.data?.productFilterString)
      setProductDetails(data?.data);
      setBreadcrumbSlugs(data?.data?.productFilterSlug?.split('/'))
      setAttributesListDetails(Object.entries(data?.data?.AttributesList || []));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching page data:", error);
      errorHandling(error);
    }
  };


  useEffect(() => {
    if (!hasFetched.current) {
      fetchProductDetails();
      hasFetched.current = true;
    }
  }, [itemCode]);

  const [currentPrice, setCurrentPrice] = useState(0);
  const [priceBreakDownId, setPriceBreakDownId] = useState(0);
  const handleQuantityChange = (itemCode, type, customQuantity = null) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      let currentQuantity = newQuantities[itemCode] || 1;

      if (customQuantity !== null) {
        currentQuantity = parseInt(customQuantity, 10);
        if (isNaN(currentQuantity) || currentQuantity < 1) {
          currentQuantity = 1; // Ensure quantity is always at least 1
        }
      } else if (type === "plus") {
        currentQuantity += 1;
      } else if (type === "minus" && currentQuantity > 1) {
        currentQuantity -= 1;
      }

      newQuantities[itemCode] = currentQuantity;

      // New logic to update price based on quantity
      const priceBreaks = productDetails?.PricesBreakdown || []; // Use the correct source for price breaks
      let newPrice = 0;
      let newPriceBreakDownId = null; // Declare the variable here

      for (let i = 0; i < priceBreaks.length; i++) {
        if (currentQuantity >= priceBreaks[i].Quantity) {
          newPrice = priceBreaks[i].SpecialPrice;
          newPriceBreakDownId = priceBreaks[i].id; // Use the correct ID
        } else {
          break; // Stop checking once we find a price break that doesn't apply
        }
      }

      // Update the state with the new price
      setCurrentPrice(newPrice); // Update the current price
      setPriceBreakDownId(newPriceBreakDownId); // Update the price breakdown ID

      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: currentQuantity > 1,
      }));

      return newQuantities;
    });
  };

  // Ensure handlePriceBreakdownClick updates selectedPriceBreakdown correctly



  // New function to handle the toggle of priceDropdownVisible
  const togglePriceDropdown = (itemCode) => {
    if (!priceDropdownVisible[itemCode]) {
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: !prevVisible[itemCode],
      }));
    }
  };

  const handlePriceBreakdownClick = (itemCode, quantity, priceBreakDownId, price) => {
    setPriceBreakDown(priceBreakDownId);
    setSelectedPriceBreakdown((prevBreakdowns) => ({
      ...prevBreakdowns,
      [itemCode]: {
        quantity, // Set the quantity from the breakdown
        price, // Use the passed price
        priceBreakDownId, // Set the price breakdown ID
      },
    }));
    handleQuantityChange(itemCode, null, quantity); // Call to update quantity and price
  };


  const handleAddToCart = async (product) => {
    if (product?.QuantityOnStock > 0 || (product?.QuantityOnStock <= 0 && payTermsGrp != 17)) {
      const quantity = quantities[product?.ItemCode] || 1;

      // Use the selected price breakdown for this product, if available
      const selectedBreakdown = selectedPriceBreakdown[product?.ItemCode] || {};
      // const priceBDownIdToUse = selectedBreakdown.priceBreakDownId || priceBreakDown; // Fallback to general breakdown if needed

      const product_price_id = product?.ProductPrice?.[0]?.id ? product?.ProductPrice?.[0]?.id : '';

      const url = `${pathObj.baseURL}${pathObj.addCart}`;
      const payload = {
        customer_id: customerId,
        product_id: product?.id,
        quantity: quantity, // Use the selected quantity
        price_break_down_id: priceBreakDownId ? priceBreakDownId : '', // Send the selected breakdown ID
        product_price_id,
        Csprice: selectedBreakdown.price ? selectedBreakdown.price : product?.Csprice > 0 ? product?.Csprice : 0,
        price: selectedBreakdown.price ? selectedBreakdown.price : product?.Csprice > 0 ? 0 : product?.MainProductPrice || currentPrice,
        product_itemcode: product?.ItemCode,
        customer_cardcode: compData?.CardCode,
      };

      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response.message || `${product?.ItemCode} added to cart successfully.!`);
          addToCartCount(product);
        } else {
          toast.error(response.message || "Something went wrong!");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
        errorHandling(error);
      }
    } else {
      toast.error("Item Currently not available!");
    }
  };


  const CustomNextArrow = ({ onClick, disabled }) => {
    return (
      <button
        type="button"
        data-role="none"
        className="slick-next slick-arrow"
        aria-label="Next"
        role="button"
        style={{ display: "block", opacity: disabled ? 0 : 1 }}
        onClick={!disabled ? onClick : null}
        disabled={disabled} // Disable the button
      >
        Next
      </button>
    );
  };

  const CustomPrevArrow = ({ onClick, disabled }) => {
    return (
      <button
        type="button"
        data-role="none"
        className="slick-prev slick-arrow"
        aria-label="Previous"
        role="button"
        style={{ display: "block", opacity: disabled ? 0 : 1 }}
        onClick={!disabled ? onClick : null}
        disabled={disabled} // Disable the button
      >
        Previous
      </button>
    );
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderContentRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const contentSlidesCount = imageStatus['Picture']?.length + (imageStatus['vectorPicture'] ? 1 : 0 ) || 2; // Adjust this to your total slide count

  const contentSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: imageStatus['Picture'] || imageStatus['vectorPicture'] ? true : false,
    infinite: false,
    speed: 1000,
    asNavFor: sliderThumbRef.current,
    ref: sliderContentRef,
    nextArrow: (
      <CustomNextArrow disabled={currentSlide === contentSlidesCount - 1} />
    ),
    prevArrow: <CustomPrevArrow disabled={currentSlide === 0} />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
     
      const image = newIndex === 0 ? imageStatus['vectorPicture'] : imageStatus['Picture'][newIndex];
      // const image = newIndex === 0 ?imageStatus['Picture'][newIndex] ? imageStatus['Picture'][newIndex] : imageStatus['vectorPicture'];
      
      setActiveImage(image); // Set the correct image for zoom based on the active slide
    },
  };
  let totalProdImages = imageStatus['Picture']?.length + (imageStatus['vectorPicture'] ? 1 : 0);
  const thumbSettings = {
    slidesToShow: totalProdImages > 5 ? 5 : totalProdImages,
    slidesToScroll: false,
    arrows: true,
    swipe: totalProdImages > 5 ? true : false,
    draggable: totalProdImages > 5 ? true : false,
    centerMode: false,
    focusOnSelect: true,
    infinite: false,
    asNavFor: sliderContentRef.current, // Link the two sliders
    ref: sliderThumbRef, // Attach the ref to the thumbnail slider
  };


  const handleRatingChange = async (itemCode, rating) => {
    
    if (rating && itemCode) {
      const url = `${pathObj.baseURL}/saveRating`; // Update with your API endpoint
      const payload = {
        product_id: itemCode,
        customer_id: customerId,
        rating: rating,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response?.message || "Rating submitted successfully!");
          setTimeout(() => {
            fetchProductDetails();
          }, 300);
        } else {
          toast.error(response.message || "Failed to submit rating.");
        }
      } catch (error) {
        console.error("Error submitting rating:", error);
      }
    };
  }

  const handleWishlistToggle = async (productItemCode) => {
    const currentStatus = wishlistStatus[productItemCode] || false; // Get current status of the product
    const url = `${pathObj.baseURL}/saveFavoriteItem`; // Update with your API endpoint
    const payload = {
      customer_id: customerId,
      product_id: productItemCode,
      status: currentStatus ? 0 : 1, // Toggle wishlist status
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        // Update the wishlist status for the specific product
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [productItemCode]: !currentStatus, // Toggle the wishlist status
        }));
        toast.success(response?.message || "Wishlist updated successfully!");
        setTimeout(() => {
          fetchProductDetails();
        }, 300);
      } else {
        toast.error(response.message || "Failed to update wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error("An error occurred while updating the wishlist.");
      errorHandling(error);
    }
  };

  // Ensure the image loading function sets up the correct status for each image
  const loadImages = async () => {
    const status = {};

    if (productDetails?.vectorPicture) {
      const vectorPictureCheck = await checkImage(productDetails.vectorPicture);
      status['vectorPicture'] = vectorPictureCheck?.status === 'ok' ? productDetails.vectorPicture : '';
    }

    if (productDetails?.Picture) {
      const pictureUrls = productDetails.Picture.split(',').map(url =>
        checkImage(url.trim())
      );

      const results = await Promise.allSettled(pictureUrls);
      const validPictures = results.reduce((acc, result) => {
        if (result.status === 'fulfilled' && result.value?.status === 'ok') {
          if (result.value?.path) {
            acc.push(result.value?.path);
          }
        }
        return acc;
      }, []);
      
      if (validPictures.length > 0) {
        status['Picture'] = validPictures;
      }else if(validPictures.length == 0 && !status['vectorPicture'] ){
        status['Picture'] = [`${pathObj?.s3ImagePath}/no-image.jpg`];
      }
    }

    

    setImageStatus(status);
  };

  useEffect(() => {
    if (productDetails?.length > 0 || productDetails) {
      loadImages();
    }
  }, [productDetails]);

  // useEffect(() => {
  //   if (productDetails) {
  //     loadImages();
  //   }
  // }, [productDetails]);

  const productDescription =
    `${productDetails?.U_V33_WebDescription || ''} ${productDetails?.U_V33_WebDescription2 || ''} ${productDetails?.U_V33_WebDescription3 || ''}`.trim();

  return (
    <>
      <Helmet>
        <title>
          {productDetails?.U_V33_WebProductName || "Default Product Name"} | Davenport Burgess
        </title>
        <meta
          name="description"
          content={`Product description - ${productDetails?.U_V33_WebDescription || "Default description"}`}
        />
      </Helmet>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="pd_wrap">

            <div className="container">

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <img src={`${pathObj?.s3ImagePath}/home-icon.svg`} alt="" />
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/categories/${breadcrumbSlugs[0]}`}>
                      {formattedCategory}
                    </Link>
                  </li>

                  {formattedSubCategory && breadcrumbSlugs[1] && breadcrumbSlugs[2] && <li className="breadcrumb-item" aria-current="page">
                    <Link to={`/categories/${breadcrumbSlugs[0]}/${breadcrumbSlugs[1]}`}>
                      {formattedSubCategory}
                    </Link>
                  </li>
                  }

                  {formattedSubCategory && breadcrumbSlugs[1] && !breadcrumbSlugs[2] && <li className="breadcrumb-item" aria-current="page">
                    {formattedSubCategory}
                  </li>
                  }

                  {formattedChild && breadcrumbSlugs[2] && <li className="breadcrumb-item active" aria-current="page">
                    {formattedChild}
                  </li>
                  }
                </ol>
              </nav>

              <div className="clinner">

                <div className="d-flex flex-wrap">

                {!imageStatus['Picture'] && !imageStatus['vectorPicture'] ? (
                    <div className="loader"></div>
                  ) : (
                  <div className="pdLeft">
                    <div
                      className="slider-container"
                      ref={imageContainerRef}
                      onMouseMove={(e) =>
                        handleMouseMove(
                          e,
                          imageStatus['Picture'] ||
                          imageStatus['vectorPicture'] ||
                          `${pathObj?.s3ImagePath}/no-image.jpg`
                        )
                      }
                      onMouseLeave={handleMouseLeave}
                      style={{ position: "relative" }}
                    >
                      <Slider {...contentSettings} className="slider slider-content slick-initialized slick-slider">
                        {/* Render the Picture image if its status is "ok" */}
                        {imageStatus['Picture'] && imageStatus['Picture'].length > 0 && imageStatus['Picture'].map((picture, index) => (
                          <div className="slick-slider-item dfdsfdfdsf" key={index}>
                            <img
                              src={picture}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                              }}
                            />
                          </div>
                        ))}

                        {/* Render the vectorPicture image if its status is "ok" */}
                        {imageStatus['vectorPicture'] ? (
                          <div className="slick-slider-item 778887878">
                            <img
                              src={imageStatus['vectorPicture']}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                              }}
                            />
                          </div>
                        ) : null}

                      </Slider>


                      {/* Magnifying Glass Overlay */}
                      <div
                        className="dsadsaasdsda"
                        style={{
                          ...zoom,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: imageDimensions.width,
                          height: '502px',
                          borderRadius: '15px',
                          border: 'solid 1px #FEB41D',
                          backgroundRepeat: "no-repeat",
                          backgroundSize: `${imageDimensions.width * 2}px ${imageDimensions.height * 2}px`,
                          pointerEvents: "none",
                          opacity: 1,
                        }}
                      />
                    </div>

                    {/* Thumbnail Slider */}

                    <Slider {...thumbSettings} className="slider-thumb">
                      {/* Render the Picture image if its status is "ok" */}
                      {imageStatus['Picture'] && imageStatus['Picture'].length > 0 && imageStatus['Picture'].map((picture, index) => (
                        <div className="slick-slider-item f45fd5f5df" key={index}>
                          <img
                            src={picture}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                            }}
                          />
                        </div>
                      ))}

                      {/* Render the vectorPicture image if its status is "ok" */}
                      {imageStatus['vectorPicture'] ? (
                        <div className="slick-slider-item 545dfd5f4d5f45df45s4fd5s">
                          <img
                            src={imageStatus['vectorPicture']}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                            }}
                          />
                        </div>
                      ) : null}
                      
                    </Slider>

                  </div>
                )}

                  <div className="pdRight">

                    <h1>{productDetails?.ItemCode} - {productDetails?.U_V33_WebProductName}</h1>

                    <div className="instock_row">
                      {productDetails?.QuantityOnStock ? (
                        <div className="instock">
                          <img
                            src={`${pathObj?.s3ImagePath}/instock-icon.svg`}
                            alt="In Stock"
                          />{" "}
                          In Stock
                        </div>
                      ) : (
                        <div className="outofstock">
                          <img
                            src={`${pathObj?.s3ImagePath}/out-stock.svg`}
                            alt="Currently not available"
                          />{" "}
                          Currently not available
                        </div>
                      )}
                      {token && (
                        <div className="wishlist">
                          <img
                            src={`${pathObj?.s3ImagePath}/wishlist-icon${wishlistStatus[productDetails?.id] ? '-red' : ''}.svg`} // Check status from wishlistStatus state
                            alt="Wishlist Icon"
                            onClick={() => handleWishlistToggle(productDetails?.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="price_rating">

                      {token && (
                        <>
                          <div className="price">{currencySymbol}{productDetails?.Csprice > 0 ? productDetails?.Csprice : productDetails?.MinProductPrice || productDetails?.MainProductPrice}</div>
                          <div className="prd_rating">
                            {[1, 2, 3, 4, 5].map((rating) => {
                              const isFullStar = rating <= Math.floor(productDetails?.rating); // Check for full stars
                              const isHalfStar = rating > Math.floor(productDetails?.rating) && rating < Math.ceil(productDetails?.rating); // Check for half star
                              return (
                                <img
                                  key={rating}
                                  src={isFullStar ? `${pathObj?.s3ImagePath}/rating-icon.svg` : isHalfStar ? `${pathObj?.s3ImagePath}/half-rating-icon.svg` : `${pathObj?.s3ImagePath}/empty-rating-icon.svg`}
                                  alt={`${rating} stars`}
                                  onClick={() => {
                                    handleRatingChange(productDetails?.id, rating);
                                  }}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                    {productDescription && <div className="prd_details">
                      <h4>Description</h4>
                      <ul>
                        <li>
                          <span>{productDescription ? productDescription : ''} </span>
                        </li>
                      </ul>
                    </div>}

                    <div className="prd_details">
                      {attributesListDetails?.length > 0 && <h4>Product Details</h4>}
                      <ul>
                        {attributesListDetails.map(([key, attribute]) => {
                          return (
                            <li key={attribute?.filter_label}>
                              <span>{attribute?.filter_label}</span>
                              <span>{attribute?.filter_label_data.join(', ')}</span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    {token ? (
                      <>
                        <div className="add_qty">
                          <div className="qty_plusminus"
                            onClick={() => togglePriceDropdown(productDetails?.ItemCode)}
                            style={{ cursor: "pointer" }}>
                            <button
                              type="button"
                              data-type="minus"
                              onClick={() =>
                                handleQuantityChange(productDetails.ItemCode, "minus")
                              }
                            >
                              <img src={`${pathObj?.s3ImagePath}/minus-icon.svg`} alt="" />
                            </button>
                            <input
                              type="text"
                              value={quantities[productDetails?.ItemCode] || 1}
                              onChange={(e) => handleQuantityChange(productDetails.ItemCode, "input", e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                  e.preventDefault(); // Prevent entering minus, plus, and exponential (e) symbols
                                }
                              }}
                            />
                            <button
                              type="button"
                              data-type="plus"
                              onClick={() => handleQuantityChange(productDetails?.ItemCode, "plus")}
                            >
                              <img
                                src={`${pathObj?.s3ImagePath}/plus-icon.svg`}
                                alt=""
                              />
                            </button>
                          </div>
                          <div className="add_btn">
                            <a
                              // href="#"
                              className="button2"
                              onClick={() => handleAddToCart(productDetails, currentPrice)}
                            >
                              Add
                            </a>
                          </div>
                        </div>
                        <div
                          className="price_dropdow"
                          style={{
                            display:
                              priceDropdownVisible[productDetails?.ItemCode] &&
                                productDetails?.PricesBreakdown?.length > 0
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="more_qty">
                            {productDetails?.PricesBreakdown?.map((priceBreakdown, index) => {
                              return (
                                <div
                                  className="more_qty_row"
                                  key={priceBreakdown.id}
                                  onClick={() =>
                                    handlePriceBreakdownClick(
                                      productDetails?.ItemCode,
                                      priceBreakdown?.Quantity,
                                      priceBreakdown?.id,
                                      priceBreakdown?.SpecialPrice // Pass the price here
                                    )
                                  }
                                  // onClick={() => handlePriceBreakdownClick(productDetails?.ItemCode, priceBreakdown?.Quantity, priceBreakdown?.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <label>
                                    {priceBreakdown?.Quantity}qty
                                  </label>
                                  <span>
                                    {currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        </div>

                      </>
                    ) : (
                      <>
                        <br />
                        <Link to="/login">
                          <input className="button4" type="submit" value=" Login to see price" />
                        </Link>
                      </>
                    )}

                  </div>

                </div>
                <AlternativeRelativeProducts />
              </div>

            </div>

          </div>
        </>
      )}
    </>
  )
}

export default ProductDetails
