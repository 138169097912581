import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import pathObj from "../../services/Config";
import { CommonService } from "../../services/common";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { checkImage, decryptData, errorHandling, UpdatedDecimalDigits } from "../../helpers/common";
import AlternativeRelativeProducts from "./AlternativeRelativeProducts";
import { CartContext } from "../../context/cartContext";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import getSymbolFromCurrency from "currency-symbol-map";
const GroupProductDetails = () => {
  const { itemCode } = useParams();
  const ItemId = itemCode
  // const ItemId = itemCode.replace(/-/g, '/')
  const [productDetails, setProductDetails] = useState([]);
  const [groupProductDetails, setGroupProductDetails] = useState([]);
  const [quantities, setQuantities] = useState({});
  const hasFetched = useRef(false);
  const userInfo = window.localStorage.getItem('userData')
  const userD = decryptData(userInfo)
  const compData = JSON.parse(userD)
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const customerId = compData?.customer_id;
  const [priceBreakDown, setPriceBreakDown] = useState('');
  const { addToCartCount } = useContext(CartContext);
  const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
  const category = localStorage.getItem('categoriesName')
  const productType = localStorage.getItem('productType')
  const token = localStorage.getItem("Token");
  const [wishlistStatus, setWishlistStatus] = useState({});
  const [imageStatus, setImageStatus] = useState({});
  const [zoom, setZoom] = useState({ display: 'none', backgroundPosition: 'center', backgroundImage: '' });
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [activeImage, setActiveImage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [breadcrumbSlugs, setBreadcrumbSlugs] = useState([]);
  // const productFilter = localStorage.getItem("productFilterString");

  const productUrlFilter = localStorage.getItem("productFilterString");

  // Decode the URL-encoded string
  const decodedFilter = decodeURIComponent(productUrlFilter);

  // Split the string by '|'
  const categories = decodedFilter.split('|');

  // Assign each category to a variable
  const formattedUrlCategory = categories[0];
  const formattedUrlSubCategory = categories[1];
  const formattedUrlChild = categories[2];

  const location = useLocation();
  const productFilter = location?.pathname.replace('/products/', '').split('/').join('|')
  
  const payTermsGrp = compData?.PayTermsGrpCode;

  const [categoryName, subCategoryName, childName] = productFilter.split('|');
  const formattedCategory = categoryName.replace(/\s+/g, '-').replace(/[()]/g, '').toLowerCase();
  const formattedSubCategory = subCategoryName.replace(/\s+/g, '-').replace(/[()]/g, '').toLowerCase();

  const imageContainerRef = useRef(null);
  // Mouse move handler for the zoom effect
  const handleMouseMove = (e) => {
    if (!activeImage) return; // Ensure an image is selected
    const { left, top, width, height } = imageContainerRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    setZoom({
      display: 'block',
      backgroundImage: `url(${activeImage})`,
      backgroundPosition: `${x}% ${y}%`,
    });
    setImageDimensions({ width, height });
  };

  // Initial active image load when product details are fetched
  useEffect(() => {
    if (imageStatus['Picture']) {
      setActiveImage(imageStatus['Picture'][0]); // Set the first image as the default active image
    }else if(imageStatus['vectorPicture']){
      setActiveImage(imageStatus['vectorPicture']);
    }
  }, [imageStatus]);

  // Mouse leave handler to remove zoom effect
  const handleMouseLeave = () => {
    setZoom({ display: 'none', backgroundPosition: 'center', backgroundImage: '' });
  };

  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const Currency = compData?.Currency;
      const PriceListNum = compData?.PriceListNum;
      const customer_id = customerId;
      const url = `${pathObj.baseURL}${pathObj.getProductDetails}?ItemCode=${ItemId}`;
      
      const payload = {
        Currency,
        PriceListNum,
        customer_id,
        category,
        productType,
      };
      const data = await CommonService.getAllWithBodyWithToken(url, payload);
      localStorage.setItem('productFilterString', data?.data?.productFilterString)
      setProductDetails(data?.data);
      setBreadcrumbSlugs(data?.data?.productFilterSlug?.split('/'))
      setGroupProductDetails(data?.data?.GrpProdutsList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching page data:", error);
      errorHandling(error);
    }
  };


  useEffect(() => {
    if (!hasFetched.current) {
      fetchProductDetails();
      hasFetched.current = true;
    }
  }, [itemCode]);

  const handleQuantityChange = (itemCode, type, customQuantity = null) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      let currentQuantity = newQuantities[itemCode] || 1;
      currentQuantity = parseInt(currentQuantity)

      // If customQuantity is passed (from price breakdown), set it directly
      if (customQuantity !== null) {
        currentQuantity = parseInt(customQuantity);
      } else {
        currentQuantity = parseInt(currentQuantity)
        if (type === "plus") {
          currentQuantity += 1;
        } else if (type === "minus" && currentQuantity > 1) {
          currentQuantity -= 1;
        }
      }

      // Update the quantity for the product
      newQuantities[itemCode] = currentQuantity;

      // Show price dropdown if the quantity is updated manually
      if (customQuantity === null) {
        setPriceDropdownVisible((prevVisible) => ({
          ...prevVisible,
          [itemCode]: currentQuantity > 1,
        }));
      }

      return newQuantities;
    });
  };

  const togglePriceDropdown = (itemCode) => {
    if (!priceDropdownVisible[itemCode]) {
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: !prevVisible[itemCode],
      }));
    }
  };


  const handlePriceBreakdownClick = (itemCode, quantity, priceBreakDownId) => {
    setPriceBreakDown(priceBreakDownId)
    // Set the quantity to the selected breakdown quantity
    handleQuantityChange(itemCode, null, quantity);
  };

  const handleAddToCart = async (product) => {
    if (product?.QuantityOnStock > 0 || (product?.QuantityOnStock <= 0 && payTermsGrp != 17)) {
      const quantity = quantities[product?.ItemCode] || 1;
      const priceBDownIdToUse = product?.PricesBreakdown?.length > 0 ? priceBreakDown : '';
      const product_price_id = product?.ProductPrice?.[0]?.id ? product?.ProductPrice?.[0]?.id : '';
      const url = `${pathObj.baseURL}${pathObj.addCart}`;
      const payload = {
        customer_id: customerId,
        product_id: product?.id,
        quantity,
        price_break_down_id: priceBDownIdToUse,
        product_price_id,
        product_itemcode: product?.ItemCode,
        customer_cardcode: compData?.CardCode,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response.message || `${product?.ItemCode} added to cart  successfully.!`);
          // Increment cart count by the quantity added
          // setCartCount(prevCount => prevCount + quantity);
          addToCartCount(product);
        } else {
          toast.error(response.message || "Something went wrong!");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
        errorHandling(error);
      }
    } else {
      toast.error("Item Currently not available!");
    }
  };

  const CustomNextArrow = ({ onClick, disabled }) => {
    return (
      <button
        type="button"
        data-role="none"
        className="slick-next slick-arrow"
        aria-label="Next"
        role="button"
        style={{ display: "block", opacity: disabled ? 0 : 1 }}
        onClick={!disabled ? onClick : null}
        disabled={disabled} // Disable the button
      >
        Next
      </button>
    );
  };

  const CustomPrevArrow = ({ onClick, disabled }) => {
    return (
      <button
        type="button"
        data-role="none"
        className="slick-prev slick-arrow"
        aria-label="Previous"
        role="button"
        style={{ display: "block", opacity: disabled ? 0 : 1 }}
        onClick={!disabled ? onClick : null}
        disabled={disabled} // Disable the button
      >
        Previous
      </button>
    );
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderContentRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const contentSlidesCount = imageStatus['Picture']?.length + (imageStatus['vectorPicture'] ? 1 : 0 ) || 2; // Adjust this to your total slide count

  const contentSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: imageStatus['Picture'] || imageStatus['vectorPicture'] ? true : false,
    infinite: false,
    speed: 1000,
    asNavFor: sliderThumbRef.current,
    ref: sliderContentRef,
    nextArrow: (
      <CustomNextArrow disabled={currentSlide === contentSlidesCount - 1} />
    ),
    prevArrow: <CustomPrevArrow disabled={currentSlide === 0} />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
      // const image = newIndex === 0 ? imageStatus['Picture'] : imageStatus['vectorPicture'];
      // const image = imageStatus['Picture'][newIndex] ? imageStatus['Picture'][newIndex] : imageStatus['vectorPicture'];
      const image = newIndex === 0 ? imageStatus['vectorPicture'] : imageStatus['Picture'][newIndex];
      setActiveImage(image); // Set the correct image for zoom based on the active slide
    },
  };
  let totalProdImages = imageStatus['Picture']?.length + (imageStatus['vectorPicture'] ? 1 : 0);
  const thumbSettings = {
    slidesToShow: totalProdImages > 5 ? 5 : totalProdImages,
    slidesToScroll: false,
    arrows: true,
    swipe: totalProdImages > 5 ? true : false,
    draggable: totalProdImages > 5 ? true : false,
    centerMode: false,
    focusOnSelect: true,
    infinite: false,
    asNavFor: sliderContentRef.current, // Link the two sliders
    ref: sliderThumbRef, // Attach the ref to the thumbnail slider
  };

  const handleRatingChange = async (itemCode, rating) => {
    if (rating && itemCode) {
      const url = `${pathObj.baseURL}/saveRating`; // Update with your API endpoint
      const payload = {
        product_id: itemCode,
        customer_id: customerId,
        rating: rating,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response?.message || "Rating submitted successfully!");
          setTimeout(() => {
            fetchProductDetails();
          }, 300);
        } else {
          toast.error(response.message || "Failed to submit rating.");
        }
      } catch (error) {
        console.error("Error submitting rating:", error);
      }
    };
  }

  const loadImages = async () => {
    const status = {};

    if (productDetails?.vectorPicture) {
      const vectorPictureCheck = await checkImage(productDetails.vectorPicture);
      status['vectorPicture'] = vectorPictureCheck?.status === 'ok' ? productDetails.vectorPicture : '';
    }

    if (productDetails?.Picture) {
      const pictureUrls = productDetails.Picture.split(',').map(url =>
        checkImage(url.trim())
      );

      const results = await Promise.allSettled(pictureUrls);
      const validPictures = results.reduce((acc, result) => {
        if (result.status === 'fulfilled' && result.value?.status === 'ok') {
          if (result.value?.path) {
            acc.push(result.value?.path);
          }
        }
        return acc;
      }, []);
      
      if (validPictures.length > 0) {
        status['Picture'] = validPictures;
      }else if(validPictures.length == 0 && !status['vectorPicture'] ){
        status['Picture'] = [`${pathObj?.s3ImagePath}/no-image.jpg`];
      }
    }   

    setImageStatus(status);
  };

  useEffect(() => {
    if (productDetails?.length > 0 || productDetails) {
      loadImages();
    }
  }, [productDetails]);

  const handleWishlistToggle = async (productItemCode) => {
    const currentStatus = wishlistStatus[productItemCode] || false; // Get current status of the product
    const url = `${pathObj.baseURL}/saveFavoriteItem`; // Update with your API endpoint
    const payload = {
      customer_id: customerId,
      product_id: productItemCode,
      status: currentStatus ? 0 : 1, // Toggle wishlist status
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        // Update the wishlist status for the specific product
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [productItemCode]: !currentStatus, // Toggle the wishlist status
        }));
        toast.success(response?.message || "Wishlist updated successfully!");
        setTimeout(() => {
          fetchProductDetails();
        }, 300);
      } else {
        toast.error(response.message || "Failed to update wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error("An error occurred while updating the wishlist.");
      errorHandling(error);
    }
  };

  const groupProductDescription =
    `${productDetails?.U_V33_WebDescription || ''} ${productDetails?.U_V33_WebDescription2 || ''} ${productDetails?.U_V33_WebDescription3 || ''}`.trim();

  return (
    <>
      <Helmet>
        <title>
          {productDetails?.U_V33_WebProductName || "Default Product Name"} | Davenport Burgess
        </title>
        <meta
          name="description"
          content={`Product description - ${productDetails?.U_V33_WebDescription || "Default description"}`}
        />
      </Helmet>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="pd_wrap">

            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <img src={`${pathObj?.s3ImagePath}/home-icon.svg`} alt="" />
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/categories/${breadcrumbSlugs[0]}`}>
                      {formattedUrlCategory}
                    </Link>
                  </li>
                  {formattedUrlSubCategory && breadcrumbSlugs[1] && breadcrumbSlugs[2] && <li className="breadcrumb-item" aria-current="page">
                    <Link to={`/categories/${breadcrumbSlugs[0]}/${breadcrumbSlugs[1]}`}>
                      {formattedUrlSubCategory}
                    </Link>
                  </li>
                  }

                  {formattedUrlSubCategory && breadcrumbSlugs[1] && !breadcrumbSlugs[2] && <li className="breadcrumb-item" aria-current="page">
                      {formattedUrlSubCategory}
                  </li>
                  }
                  {formattedUrlChild && breadcrumbSlugs[2] && <li className="breadcrumb-item active" aria-current="page">
                    {formattedUrlChild}
                  </li>
                  }
                </ol>
              </nav>
              <div className="clinner">

                <div className="d-flex flex-wrap">
                {!imageStatus['Picture'] && !imageStatus['vectorPicture'] ? (
                    <div className="loader"></div>
                  ) : (
                  <div className="pdLeft">
                    {/* <div className="gpd_img"><img style={{ width: '221px' }} src={productDetails?.Picture ? productDetails.Picture : (productDetails?.vectorPicture ? productDetails.vectorPicture : `${pathObj?.s3ImagePath}/no-image.jpg`)} alt="Alt" /></div> */}
                    <div
                      className="slider-container"
                      ref={imageContainerRef}
                      onMouseMove={(e) =>
                        handleMouseMove(
                          e,
                          imageStatus['Picture'] ||
                          imageStatus['vectorPicture'] ||
                          `${pathObj?.s3ImagePath}/no-image.jpg`
                        )
                      }
                      onMouseLeave={handleMouseLeave}
                      style={{ position: "relative" }}
                    >
                      <Slider {...contentSettings} className="slider slider-content slick-initialized slick-slider">
                        {/* Render the Picture image if its status is "ok" */}
                        {imageStatus['Picture'] && imageStatus['Picture'].length > 0 && imageStatus['Picture'].map((picture, index) => (
                          <div className="slick-slider-item" key={index}>
                            <img
                              src={picture}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                              }}
                            />
                          </div>
                        ))}

                        {/* Render the vectorPicture image if its status is "ok" */}
                        {imageStatus['vectorPicture'] ? (
                          <div className="slick-slider-item">
                            <img
                              src={imageStatus['vectorPicture']}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                              }}
                            />
                          </div>
                        ) : null}

                      </Slider>


                      {/* Magnifying Glass Overlay */}
                      <div
                        className="dsadsaasdsda"
                        style={{
                          ...zoom,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: imageDimensions.width,
                          height: '502px',
                          borderRadius: '15px',
                          border: 'solid 1px #FEB41D',
                          backgroundRepeat: "no-repeat",
                          backgroundSize: `${imageDimensions.width * 2}px ${imageDimensions.height * 2}px`,
                          pointerEvents: "none",
                          opacity: 1,
                        }}
                      />
                    </div>

                    {/* Thumbnail Slider */}

                    <Slider {...thumbSettings} className="slider-thumb">
                      {/* Render the Picture image if its status is "ok" */}
                      {imageStatus['Picture'] && imageStatus['Picture'].length > 0 && imageStatus['Picture'].map((picture, index) => (
                        <div className="slick-slider-item" key={index}>
                          <img
                            src={picture}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                            }}
                          />
                        </div>
                      ))}

                      {/* Render the vectorPicture image if its status is "ok" */}
                      {imageStatus['vectorPicture'] ? (
                        <div className="slick-slider-item">
                          <img
                            src={imageStatus['vectorPicture']}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                            }}
                          />
                        </div>
                      ) : null}
                      
                    </Slider>
                  </div>
                  )}
                  <div className="pdRight">

                    <h1>{productDetails?.U_V33_WebProductName}</h1>

                    {/* <div className="instock_row">
                  {productDetails?.QuantityOnStock ? (
                    <div className="instock">
                      <img
                        src={`${pathObj?.s3ImagePath}/instock-icon.svg`}
                        alt="In Stock"
                      />{" "}
                      In Stock
                    </div>
                  ) : (
                    <div className="outofstock">
                      <img
                        src={`${pathObj?.s3ImagePath}/out-stock.svg`}
                        alt="Currently not available"
                      />{" "}
                      Currently not available
                    </div>
                  )}
                  {token && (
                    <div className="wishlist">
                      <img
                        src={`${pathObj?.s3ImagePath}/wishlist-icon${productDetails?.favorite === 1 ? '-red' : ''}.svg`} // Change the icon based on product's favorite status
                        alt="Wishlist Icon"
                        onClick={() => handleWishlistToggle(productDetails?.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  )}
                </div> 

                <div className="price_rating">

                  {token && (
                    <>
                      <div className="price"></div>
                      <div className="prd_rating">
                        {[1, 2, 3, 4, 5].map((rating) => {
                          const isFullStar = rating <= Math.floor(productDetails?.rating); // Check for full stars
                          const isHalfStar = rating > Math.floor(productDetails?.rating) && rating < Math.ceil(productDetails?.rating); // Check for half star
                          return (
                            <img
                              key={rating}
                              src={isFullStar ? `${pathObj?.s3ImagePath}/rating-icon.svg` : isHalfStar ? `${pathObj?.s3ImagePath}/half-rating-icon.svg` : `${pathObj?.s3ImagePath}/empty-rating-icon.svg`}
                              alt={`${rating} stars`}
                              onClick={() => {
                                handleRatingChange(productDetails?.id, rating);
                              }}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              */}   
                {groupProductDescription && <div className="prd_details">
                      <h4>Description</h4>
                      <ul>
                        <li>
                          <span>{groupProductDescription ? groupProductDescription : ''} </span>
                        </li>
                      </ul>

                    </div>
                }
                    

                  </div>

                </div>
                <div className="gpd_cart">

                  <div className="cart_left">

                    <div className="cart_tbl">

                      <table className="table">

                        <thead>

                          <tr>
                            <th className="product_thumbnail">Product</th>
                            <th className="product_name">&nbsp;</th>
                            <th className="product_description">Description</th>
                            <th className="product_price">Price</th>
                            <th className="product_quantity text-center">Quantity</th>
                            <th className="product_stock">Stock</th>
                            <th></th>
                          </tr>

                        </thead>

                        <tbody>
                          {groupProductDetails?.length > 0 ? (
                            <>
                              {/* Dynamic rows from groupProductDetails */}
                              {groupProductDetails?.map((product) => (
                                <>
                                <tr key={product?.id}>
                                  <td className="product_thumbnail"><Link to={`/products/${product?.ItemCode?.replace(/\//g, '__')}`}>
                                    <img
                                      src={product?.Picture ? product?.Picture : `${pathObj?.s3ImagePath}/no-image.jpg`}
                                      onError={(e) => {
                                        e.target.onerror = null; // Prevent looping
                                        e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`; // Fallback image
                                      }}
                                      alt={product?.U_V33_WebProductName}
                                    // onClick={() => handleProductGroupDetailsClick(product)}
                                    /></Link></td>
                                  <td className="product_name">
                                    <h4><Link to={`/products/${product?.ItemCode?.replace(/\//g, '__')}`}>{product?.ItemCode}</Link></h4>
                                    <p>{product?.U_V33_WebProductName}</p>
                                  </td>
                                  <td className="product_description" data-title="Description">{product?.U_V33_WebDescription || ''} {product?.U_V33_WebDescription2 || ''} {product?.U_V33_WebDescription3 || ''}</td>
                                  {token ? (
                                    <>
                                      <td className="product_price" data-title="Price">{currencySymbol}{product?.MainProductPrice}</td>
                                      <td className="product_quantity text-center" data-title="Quantity">
                                        <div className="cart_qty" style={{ width: '100px' }}>
                                          <div className="qty_plusminus"
                                            onClick={() => togglePriceDropdown(product?.ItemCode)}
                                            style={{ cursor: "pointer" }}>
                                            <button
                                              type="button"
                                              data-type="minus"
                                              onClick={() => handleQuantityChange(product.ItemCode, "minus")}
                                            >
                                              <img src={`${pathObj?.s3ImagePath}/minus-icon.svg`} alt="" />
                                            </button>
                                            <input
                                              type="text"
                                              style={{ width: '40px' }}
                                              value={quantities[product?.ItemCode] || 1}
                                              onChange={(e) => handleQuantityChange(product.ItemCode, "input", e.target.value)}
                                              onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === 'e') {
                                                  e.preventDefault(); // Prevent entering minus, plus, and exponential (e) symbols
                                                }
                                              }}
                                            />
                                            <button
                                              type="button"
                                              data-type="plus"
                                              onClick={() => handleQuantityChange(product.ItemCode, "plus")}
                                            >
                                              <img src={`${pathObj?.s3ImagePath}/plus-icon.svg`} alt="" />
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="price_dropdow"
                                          style={{
                                            display:
                                              priceDropdownVisible[product?.ItemCode] && product?.PricesBreakdown?.length > 0
                                                ? 'block'
                                                : 'none',
                                          }}
                                        >
                                          <div className="more_qty">
                                            {product?.PricesBreakdown?.map((priceBreakdown, index) => (
                                              <div
                                                className="more_qty_row"
                                                key={priceBreakdown.id}
                                                onClick={() => handlePriceBreakdownClick(product?.ItemCode, priceBreakdown?.Quantity, priceBreakdown?.id)}
                                                style={{ cursor: 'pointer' }}
                                              >
                                                <label>{priceBreakdown?.Quantity} qty</label>
                                                <span>{currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}</span>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="product_stock" data-title="Stock">
                                        {product?.QuantityOnStock ? (
                                          <div className="instock">
                                            <img
                                              src={`${pathObj?.s3ImagePath}/instock-icon.svg`}
                                              alt="In Stock"
                                            />{" "}
                                            In Stock
                                          </div>
                                        ) : (
                                          <div className="outofstock">
                                            <img
                                              src={`${pathObj?.s3ImagePath}/out-stock.svg`}
                                              alt="Currently not available"
                                            />{" "}
                                            Currently not available
                                          </div>
                                        )}
                                        {/* {token && (
                                              <div className="wishlist">
                                                <img
                                                  src={`${pathObj?.s3ImagePath}/wishlist-icon${productDetails?.favorite === 1 ? '-red' : ''}.svg`} // Change the icon based on product's favorite status
                                                  alt="Wishlist Icon"
                                                  onClick={() => handleWishlistToggle(productDetails?.id)}
                                                  style={{ cursor: 'pointer' }}
                                                />
                                              </div>
                                            )} */}
                                      </td>
                                      <td>
                                        <div className="add_btn">
                                          <a
                                            href="#"
                                            className="button2"
                                            onClick={() => handleAddToCart(product)} // Call the function on click
                                          >
                                            Add
                                          </a>
                                        </div>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <Link to="/login">
                                          <input className="button4" type="submit" value=" Login to see price" />
                                        </Link>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      {/* <td>
                                <Link to="/login">
                                  <input className="button4" type="submit" value=" Login to see quantity" />
                                </Link>
                              </td>
                              <td>
                                <Link to="/login">
                                  <input className="button4" type="submit" value=" Login to see stock" />
                                </Link>
                              </td> */}
                                    </>
                                  )}
                                </tr>
                                </>
                              ))}
                            </>
                          ) : ('No Products Found')}
                        </tbody>

                      </table>

                    </div>

                  </div>

                </div>
                <AlternativeRelativeProducts />
              </div>

            </div>

          </div>
        </>
      )}
    </>
  )
}

export default GroupProductDetails
